@tailwind base;
@tailwind components;
@tailwind utilities;

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

@layer components {
  .BGImage {
    background: bottom center no-repeat fixed url("../public/background.png");
    filter: opacity(40%);
  }

  .AppGrid {
    display: grid;
    overflow: hidden;
    height: 100vh;
    grid-template-areas:
      "Lost Forest Hub Usage"
      ". . Staking Referral"
      ". . Locking .";
    grid-template-columns: 100vw 100vw 100vw 100vw;
    grid-template-rows: 100vh 100vh 100vh;
    gap: 0;
  }
  .App {
    overflow: auto;
  }
  .LostApp {
    grid-area: Lost;
  }
  .ForestApp {
    grid-area: Forest;
  }
  .HubApp {
    grid-area: Hub;
  }
  .UsageApp {
    grid-area: Usage;
  }
  .StakingApp {
    grid-area: Staking;
  }
  .LockingApp {
    grid-area: Locking;
  }
  .ReferralApp {
    grid-area: Referral;
  }

  @keyframes float-up-down {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(32px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes float-circle {
    from { transform: rotate(0deg) translateX(5px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(5px) rotate(-360deg); }
  }

  .FloatingTree {
    animation: 4s ease-in-out infinite float-up-down;
  }

  .ForestTree {
    animation: 8s linear infinite float-circle;
  }
  
  .appearance-textfield {
    appearance: textfield;
  }

  tree-checker#checked ~ .checked-tree {
    visibility: visible;
  }
}

.NavArrow {
  font-size: 0;
  line-height: 0;
  width: 0;
}

.NavArrowUp {
  border-bottom: 20px solid #4aa172;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.NavArrowDown {
  border-top: 20px solid #4aa172;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.NavArrowLeft {
  border-top: 10px solid transparent;
  border-right: 20px solid #4aa172;
  border-bottom: 10px solid transparent;
}

.NavArrowRight {
  border-top: 10px solid transparent;
  border-left: 20px solid #4aa172;
  border-bottom: 10px solid transparent;
}


/* TODO TEMP do properly with tailwind or whatever */
.tree {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
